(function ($) {

    'use strict';

    var site = {
            breakpoints: {
                xs: 420,
                sm: 768,
                md: 992,
                lg: 1200,
                xl: 1600
            },
            scrolling: false,
            cache: {},
            timeout: {},
            templates: {}
        },
        spin = {
            lines: 13,
            length: 1,
            width: 5,
            radius: 6,
            scale: 1,
            corners: 1,
            color: '#FFF',
            opacity: 0.25,
            rotate: 0,
            direction: 1,
            speed: 1,
            trail: 60,
            fps: 20,
            zIndex: 2e9,
            className: 'spinner',
            top: '50%',
            left: '0',
            shadow: false,
            hwaccel: false,
            position: 'absolute'
        };

    // RESPONSIVE
    function responsiveMenu(flag) {
        var $menu = $('#menu');
        if (flag) {
            $menu.removeClass('menu-nav--default').addClass('menu-nav--responsive');
        } else {
            $menu.removeClass('menu-nav--responsive').addClass('menu-nav--default');
            $menu.find('.menu').removeClass('menu__item--active');
            $menu.find('.menu__submenu').css({
                display: ''
            });
        }
    }
    function changeDateInputs(flag) {
        var $menu = $('#menu');

        function datePickerInit(el) {
            var date = new Date(),
                self = el,
                currentDate = $(self).datepicker('getDate');
            if (!currentDate) {
                $(self).datepicker('setDate', date);
                testEmptyField(self);
                $('.ui-datepicker-prev, .ui-datepicker-next').remove();
            }
        }

        if (flag) {
            $('[data-plugin=datepicker]').attr('type', 'text').datepicker({
                changeMonth: true,
                changeYear: true,
                dateFormat: 'dd-mm-yy'
            }).on('focus', function () {
                datePickerInit(this);
            });
        } else {
            $('[data-plugin=datepicker]').attr('type', 'date').datepicker('destroy').off('focus', function () {
                datePickerInit(this);
            });
        }

    }
    function resize(event) {
        var $window = $(window);

        if ($window.width() < site.breakpoints.md) {
            // reponsive menu
            responsiveMenu(true);

            // datepicker
            changeDateInputs(false);

        } else {
            // reponsive menu
            responsiveMenu(false);

            // datepicker
            changeDateInputs(true);

            // body status
            $('body').removeClass('menu-active sidebar-active');
        }
    }
    // FORMS
    function cycleBlocks($form) {
        var data = {},
            $fields = $form.find('input, select, textarea'),
            $blocks = $form.find('[data-block]'),
            $ignore = $($form.data('ignore'));

        // traverse data
        function traverseObject(data) {
            var prop,
                prop2;
            for (prop in data) {
                if (typeof data[prop] === 'object') {
                    traverseObject(data[prop]);
                    // verify if has array
                    for (prop2 in data[prop]) {
                        if (prop2.indexOf(prop) > -1) {
                            // object to array
                            data[prop] = $.map(data[prop], function(value, index) {
                                return [value];
                            });
                            break;
                        }
                    }
                }
            }
        }
        // test check
        function testCheck(input) {
            var $v = input.val(),
                    $t = input.attr('type');
            if ($t === 'radio') {
                if (input.prop('checked')) {
                    return Number($v);
                }
            } else if ($t === 'checkbox') {
                return input.prop('checked');
            } else {
                return $v;
            }
            return undefined;
        }
        // check ignored elements
        function checkIgnore(input) {
            if (input.closest($ignore).length) {
                return true;
            }
            return false;
        }
        // get key
        function getKey(data, block) {
            var aux = {},
                key;
            block = block.parent().closest('[data-block]');
            if (block.length && block.closest('.form').length) {
                key = (block.data('block') || null);
                if (key === null) {
                    aux = data;
                } else {
                    aux[key] = data;
                }
                return getKey(aux, block);
            } else {
                return data;
            }
        }

        // cycle fields
        $fields.each(function () {
            var $input = $(this),
                d = {},
                v = $input.val(),
                n = $input.attr('name'),
                $b = $input.closest('[data-block]'),
                k = ($b.data('block') || null),
                $opt = {
                    el : this
                },
                $aux,
                $field;

            // not hidden / ignored
            if (checkIgnore($input)) { //|| !$b.is(':visible')
                return;
            }

            // get value
            if (k === null) {
                d[n] = testCheck($input);
            } else {
                d[k] = {};
                d[k][n] = testCheck($input);
            }

            // get key
            d = getKey(d, $b);

            // copy data
            $.extend(true, data, d);
        });

        // objects to array
        traverseObject(data);

        // return data
        return data;
    }
    function createAlert(data, type) {
        var $alert = $('<div />'),
            $websiteAlerts = $('#website-alerts');

        // remove other alerts
        $websiteAlerts.children().remove();

        // create alert
        if (type) {
            $alert.addClass('website-alerts__alert--' + type);
        }
        $alert.addClass('website-alerts__alert');
        $alert.append(
            '<div class="website-alerts__message">' + data.message + '</div>' +
            '<span class="website-alerts__close" title="Close"><i class="icon icon-close"></i></span>'
        );
        $websiteAlerts.prepend($alert);
        $alert.fadeIn().delay(6000).fadeOut(function () {
            $(this).remove();
        });

        // close alert
        $alert.on('click', '.website-alerts__close', function (event) {
            event.preventDefault();
            $(this).closest('.website-alerts__alert').stop(true).fadeOut(function () {
                $(this).remove();
            });
        });

        return $alert;
    }
    function testEmptyField(field) {
        var $el = $(field),
            $label = $el.closest('.field');
        if ($el.val() !== '') {
            $label.addClass('field--filled');
        } else {
            $label.removeClass('field--filled');
        }
    }
    function testFieldValue(field) {
        var $el = $(field),
            $v = $el.val(),
            $label = $el.closest('.field'),
            length = $v.length,
            pos = false;

        testEmptyField(field);

        // fulldate
        if ($el.is('[data-fulldate]')) {
            pos = event.target.selectionStart;
            $el.attr('maxlength', 10);
            $v = $v.replace(/\D/g, "");
            $v = $v.replace(/(\d{2})(\d)/, "$1/$2");
            $v = $v.replace(/(\d{2}\/\d{2})(\d)/, "$1/$2");
            $v = $v.substr(0,10);
            $el.val($v);
            // test position after valus has changed
            pos = pos - (length - $el.val().length);
            // select new range
            $el[0].setSelectionRange(pos,pos);
            return;
        }

        if ($el.val() !== '') {
            $label.addClass('field--filled');
        } else {
            $label.removeClass('field--filled');
        }
    }
    function checkValue(input) {
        var $input = input,
            required = $input.attr('required'),
            type = $input.attr('type'),
            name = $input.attr('name'),
            flag = true,
            value = $input.val();


        // false / empty
        if (!required) {
            return flag;
        }

        // type
        switch (type) {
            // email
            case 'email':
                if (!/[A-Za-z0-9_.\-]+@([A-Za-z0-9_\-]+\.)+[A-Za-z]{2,4}/.test(value)) {
                    flag = false;
                }
                break;
            case 'checkbox':
                if (!$input.prop('checked')) {
                    flag = false;
                }
                break;
            // default
            default:
                if (value === '' || value === null) {
                    flag = false;
                }
                break;
        }

        // extra conditions
        // address
        if ($input.is('[data-google-address]') && !$input.data('address')) {
            flag = false;
        }
        // credit card
        if ($input.is('[data-credit-card]') && !$input.data('card')) {
            flag = false;
        }


        return flag;
    }
    function checkFields(form) {
        var $fields = form.find('input:visible, textarea:visible, select:visible').not('[type=submit]'),
            flag = true;

        // cycle fields
        $fields.each(function () {
            var $input = $(this),
                $wrapper = $input.parent(),
                value = $input.val(),
                name = $input.attr('name'),
                message = $input.data('alert') || 'This field is required.',
                $alert = $('<div />').addClass('field__alert').text(message),
                userformMessage = $input.data('msg-required');

            // userform condition
            if (userformMessage) {
                message = userformMessage;
            }

            // remove old alert
            $wrapper.removeClass('field--alert').find('.field__alert').remove();

            if (!checkValue($input)) {
                // append alert
                $wrapper.addClass('field--alert').append($alert);
                $alert.fadeIn(300);
                flag = false;
            }
        });

        return flag;
    }
    function removeBrowserFormValidate() {
        $('form').attr('novalidate', 'novalidate');
    }
    function checkAlert() {
        var $websiteAlerts = $('#website-alerts'),
            $alert = $websiteAlerts.children('.website-alerts__alert');

        if (!$alert.length) {
            return false;
        }

        // show alert
        $alert.fadeIn().delay(8000).fadeOut(function () {
            $(this).remove();
        });

        // close alert
        $alert.on('click', '.website-alerts__close', function (event) {
            event.preventDefault();
            $(this).closest('.website-alerts__alert').stop(true).fadeOut(function () {
                $(this).remove();
            });
        });
    }
    // EXTRA

    /*------------------------------------*\
        GENERAL
    \*------------------------------------*/

    checkAlert();

    // ---------------- mobile portal menu
    $('.portal-mobile').on('change', 'select', function () {
        var $self = $(this),
            url = $self.val();

            console.log($self);

        if (url) {
            window.location.href = url;
        }
    });

    // ---------------- modal hack
    $('body').on('show.bs.modal', '.modal', function () {
        var $modal = $(this),
            $parent = $modal.parent();

        // append to body
        $modal.appendTo('body');
        // keep parent ref
        $modal.data('parent', $parent);
    }).on('hidden.bs.modal', '.modal', function () {
        var $modal = $(this),
            $parent = $modal.data('parent');

        // append to original parent
        $modal.appendTo($parent);
    });
//    $('body').on('shown.bs.modal', '.modal', function () {
//        var $modal = $(this);
//        // focus first input
//        $modal.find('input:visible').first().focus();
//    });

    // ---------------- scroll to
    $('body').on('click', '[data-scrollto]', function (event) {
        event.preventDefault();
        var target = $(this).data('scrollto'),
            offset = ($(window).width() < site.breakpoints.md) ? $('#header').outerHeight(true) : 70;
        $('html, body').animate({
            scrollTop: $(target).offset().top - offset
        }, 500);
    });


    /*------------------------------------*\
        FIELDS/FORMS
    \*------------------------------------*/

    // ---------------- inputs blur validation
    $('body').on('blur', 'input, select, textarea', function () {
        var $input = $(this),
            $wrapper = $input.parent(),
            noFocus = $input.data('nofocus') || false;
        if (!noFocus) {
            checkFields($wrapper);
        }
    });

    // ---------------- inputs keyup
    $('body').on('keyup change paste input', 'input, select, textarea', function (event) {
        testFieldValue(this, event);
    });
    $('input, select, textarea').each(function () {
        testEmptyField(this);
    });

    // ---------------- inputs focus
    $('body').on('focusin', 'input, select, textarea', function () {
        $(this).closest('.field').addClass('field--focus');
    }).on('focusout', 'input, select, textarea', function () {
        $(this).closest('.field').removeClass('field--focus');
    });

    // ---------------- input check
    $('body').on('click', 'input[type=checkbox], input[type=radio]', function () {
        var $el = $(this),
            $label = $el.closest('.field'),
            type = $(this).attr('type'),
            name = $(this).attr('name');

        if (type === 'radio') {
            $('input[name=' + name + ']').closest('.field').removeClass('field--checked');
        }

        if (!$el.prop('checked')) {
            $label.removeClass('field--checked');
        } else {
            $label.addClass('field--checked');
        }
    }).each(function () {
        $(this).triggerHandler('click');
    });

    // ---------------- form submissions
    removeBrowserFormValidate();
    $('body').on('submit', 'form', function () {
        return checkFields($(this));
    });


    /*------------------------------------*\
        MENU
    \*------------------------------------*/
    // ---------------- toggle menu
    $('#toggle-menu').on('click', function (event) {
        event.preventDefault();
        $('body').toggleClass('menu-active');
    });

    // ---------------- backdrop click
    $('#backdrop').on('click', function () {
        $('body').removeClass('menu-active sidebar-active');
    });

    // ---------------- menu (default)
    $('body').on('focusin mouseenter', '#menu.menu-nav--default .menu__link', function (event) {
        var $el = $(this),
            $item = $el.parent(),
            $submenu = $item.find('.submenu');

        // reset others
        $('#menu .menu__item').not($item).removeClass('menu__item--active');

        // active item
        $item.addClass('menu__item--active');
    });
    $('body').on('mouseleave', '#menu.menu-nav--default .menu', function (event) {
        var $item = $('.menu__item--active'),
            $submenu = $('.submenu');

        // remove active item
        $item.removeClass('menu__item--active');
    });

    // ---------------- menu (responsive)
    $('body').on('click', '#menu.menu-nav--responsive .menu__link', function (event) {
        var $el = $(this),
            $item = $el.parent(),
            $submenu = $item.find('.submenu');
        if (!$submenu.length) {
            return;
        }

        event.preventDefault();
        // reset others
        $('#menu .menu__item').not($item).removeClass('menu__item--active');
        $('.submenu').not($submenu).stop(true).slideUp(200);

        // active item
        $item.toggleClass('menu__item--active');

        // animate menu
        $submenu.stop(true).slideToggle(200);
    });


    /*------------------------------------*\
        CONTACT
    \*------------------------------------*/

    // ---------------- product enquiry
    $('[data-enquire]').on('click', function (event) {
        event.preventDefault();
        var $form = $(this).closest('form'),
            $fields = $form.find('input, select, textarea'),
            url = $form.attr('action'),
            data = $form.serialize(),
            flag = false,
            $submit = $(this),
            $submitAux = $submit.children('span'),
            spinner = $.extend(true, {}, spin),
            offset = ($(window).width() < site.breakpoints.md) ? $('#header').outerHeight(true) + 5 : 70;

        // prevent multiple clicks
        if ($submit.is('.form__submit--loading, .form__submit--disabled')) {
            return false;
        }

        // validation errors
        if (!checkFields($form)) {
//            $('html, body').animate({
//                scrollTop: $form.find('.field__alert').first().offset().top - offset
//            }, 400);
            return false;
        }

        console.log(data);

        // send data
        $.ajax({
            type: 'POST',
            url: url,
            data: data,
            cache: false,
            dataType: 'json',
            beforeSend: function () {
                // add loader
                $submit.addClass('form__submit--loading');
                $submitAux.spin(spinner);
            },
            success: function (response) {
                console.log(response);

                if (response.status) {
                    if(response.redirect.length > 0){
                        console.log(response.redirect);

                        window.location.href = response.redirect;
                    } else {
                        $form.closest('.modal').modal('hide');
                        // create alert
                        createAlert(response);
                        // reset fields
                        $form[0].reset();
                    }

                } else {
                    // create alert
                    createAlert(response, 'error');
                }

                // remove loader
                $submit.removeClass('form__submit--loading');
                $submitAux.spin(false);
            },
            error: function (response) {
                // create alert
                createAlert(response, 'error');

                // remove loader
                $submit.removeClass('form__submit--loading');
                $submitAux.spin(false);
            }
	});
    });

    // ---------------- register newsletter
    $('[data-newsletter]').on('click', function (event) {
        event.preventDefault();
        var $form = $(this).closest('form'),
            $fields = $form.find('input, select, textarea'),
            url = $form.attr('action'),
            data = $form.serialize(),
            flag = false,
            $submit = $(this),
            $submitAux = $submit.children('span'),
            spinner = $.extend(true, {}, spin),
            offset = ($(window).width() < site.breakpoints.md) ? $('#header').outerHeight(true) + 5 : 70;

        // prevent multiple clicks
        if ($submit.is('.form__submit--loading, .form__submit--disabled')) {
            return false;
        }

        // validation errors
        if (!checkFields($form)) {
            $('html, body').animate({
                scrollTop: $form.find('.field__alert').first().offset().top - offset
            }, 400);
            return false;
        }

        console.log(data);

        // send data
        $.ajax({
            type: 'POST',
            url: url,
            data: data,
            cache: false,
            dataType: 'json',
            beforeSend: function () {
                // add loader
                $submit.addClass('form__submit--loading');
                $submitAux.spin(spinner);
            },
            success: function (response) {
                console.log(response);

                if (response.status) {
                    // create alert
                    createAlert(response);
                    // reset fields
                    $form[0].reset();
                } else {
                    // create alert
                    createAlert(response, 'error');
                }

                // remove loader
                $submit.removeClass('form__submit--loading');
                $submitAux.spin(false);
            },
            error: function (response) {
                response.message = 'There was an error with your request. Please try again.';
                // create alert
                createAlert(response, 'error');

                // remove loader
                $submit.removeClass('form__submit--loading');
                $submitAux.spin(false);
            }
       });
    });


    /*------------------------------------*\
        WINDOW EVENTS
    \*------------------------------------*/

    /// ---------------- window events
    $(window).on('resize', resize);
    //$(window).on('hashchange', hashChange);
    //$(window).on('scroll', scroll);
    $(window).triggerHandler('resize');
    //$(window).triggerHandler('hashchange');
    //$(window).triggerHandler('scroll');
})(jQuery);
